@media print {
  .orderListBlock {
    display: none !important;
  }
  body {
    background: white;
    min-width: 0 !important;
  }
  .break-avoid {
    page-break-inside: avoid;
  }
  .socket-overlay {
    display: none;
  }
  .banner-businesses,
  .order-data,
  .orders-pagination {
    display:none !important
  }
  .MuiDrawer-root,
  .MuiAppBar-positionFixed,
  // .MuiPaper-root,
  .order-filter,
  .aside-view-actions,
  .aside-view-head,
  .order-alert-success,
  .order-alert-warning,
  .tab-pane {
    display: none !important; 
  }
  .order-list {
    display: none !important;
  }
  .app-content {
    margin: 0;
  }

  .aside-view {
    width: 100% !important;
    height: auto;
    position: relative;
    box-shadow: none !important;

    .tab-content {
      position: static;
    }
    .aside-view-tab {
      height: auto;
      display: block;
    }
    // .tab-pane {
    //   display: none !important;
      .order-print-screen {
        display: block !important;
        .aside-view-body {
          &.ticket {
            .price-col,
            .total-table {
              display: none !important;
            }
          }
        }}
       
      // }
    // }
    // .order-print-screen {
    //   display: block !important;
    // }
  }
  .MuiAlert-standard {
    display:'block' !important
  } 
}
