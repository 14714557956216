body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}

::-webkit-scrollbar-button {
  display:none;
}

.app-main {
  min-height: 100vh;
  display: flex;
  align-items: stretch;
}

.banner-businesses {
  padding: 8px 32px;
  background: #ffffff;
  border-bottom: 1px solid #d8d8d8;
  z-index: 100;

  .form-group {
    margin-bottom: 0;
  }

  .business-field {
    width: 400px;
  }
}

.app-content {
  flex: 1;
  display: flex;
  flex-direction: column;

  margin-left: $sidebar-width;
  position: relative;
}

.content-view {
  padding: 1.5rem 2rem;
}

.reporting-filter {
  margin: -1.5rem -2rem 1.5rem;
}

.app-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.ow {
  word-wrap: break-word;
  hyphens: auto;
}

.ttc {
  text-transform: capitalize;
}

.text-blink {
  color: $danger;
  animation: fadeIn 1s infinite;
}

.btn-link {
  outline: none !important;
  box-shadow: none !important;
  font-weight: $btn-font-weight;

  .icon {
    vertical-align: text-bottom;
  }
}

.btn-clear {
  border: 0;
  box-shadow: none;
  background: none;
  appearance: none;
  outline: none !important;
}

.pre-wrap {
  white-space: pre-wrap;
}

.table-vertical-middle {
  td {
    vertical-align: middle;
  }
}

.Toastify__toast {
  min-height: 50px;
  border-radius: 4px;
}

.Toastify__toast-body {
  white-space: pre-wrap;
  padding: 0 12px;
}

.ordersCount {
  display: flex;
  align-items: flex-start;
}

.new-orders-overlay,
.update-order-overlay,
.cancel-order-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba($success, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-align: center;
  opacity: 0;
  animation: fadeIn 0.4s forwards;

  h1 {
    margin: 2rem 0;
  }

  .new-orders-count,
  .modified-order-count,
  .cancel-order-count {
    width: 287px;
    height: 287px;
    line-height: 287px;
    display: block;
    border-radius: 200px;
    font-size: 5.75rem;
    background-color: rgba(0, 0, 0, 0.05);
    margin-bottom: 37px;
  }

  .modified-order-count {
    background-color: #f4433633;
  }

  .cancel-orders-count {
    background-color: #c00028;
  }

  .order-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .order-section p {
    font-size: 52px;
    font-weight: bold;
    line-height: 1.2;
  }

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 5px;
    color: inherit;
    opacity: 1;

    i {
      font-size: 2rem;
      display: block;
    }
  }

  .allExist {
    .order-section {
      margin-left: 50px;
    }
  }
}

.update-order-overlay {
  background-color: #fcb54ae6;
}

.cancel-order-overlay {
  background-color: #cb0027;
}

.draggable-row {
  z-index: 2000;
}

.cursor-pointer {
  cursor: pointer;
}

.table-hover {
  tr {
    cursor: pointer;
  }
}

.table tr.active {
  box-shadow: inset 3px 0 0 #ff8d00;
  background-color: #ff8d0026 !important;
}

.MuiTable-root tr.active {
  box-shadow: inset 3px 0 0 #ff8d00;
  background-color: #ff8d0026 !important;
}

.btn-sm {
  i {
    font-size: 1rem;
    vertical-align: top;
    display: inline-block;
  }
}

.modal-backdrop {
  width: 100%;
  height: 100%;
}

.socket-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  padding: 1rem;
  background-color: rgba($danger, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  h5 {
    line-height: 1.6;
    pointer-events: visible;
  }
}

.align-item-baseline {
  align-items: baseline;
}

// Date Piker Day Style.

.DayPickerInput {
  .DayPickerInput-OverlayWrapper {
    z-index: 2000 !important;
    position: absolute !important;
  }

  input {
    border: none;
    width: 120px;
    height: 30px;
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  height: 34px !important;
}

.DayPicker-Day {
  padding: 0.45rem 0.55rem !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #047bfe;
}

.internal-input {
  background-color: #e9ecef;
  opacity: 1;
}

//  Custom style.

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-85 {
  width: 85% !important;
}

// Custom Font Style.

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

table,
thead,
tbody,
tfoot,
tr>form:-internal-is-html {
  display: block !important;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
  outline: none;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
  outline: none;
}

.opacity-9 {
  opacity: 0.9;
}

// .btn-loading {
//   color: transparent !important;
//   background-image: url('../assets/icons/loading.svg');
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: contain;
// }

.timePickerError .rc-time-picker-input {
  border: 1px solid #dc3545;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

legend {
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  width: initial;
  max-width: initial;
}

.main-website {
  .modal-body {
    height: calc(100vh / 1.3);
    overflow: auto;
  }
}

.tabs-override-main {
  .MuiButtonBase-root {
    flex-shrink: inherit;
    white-space: inherit;
    text-align: left;
  }
}

#menu-brandName {
  max-height: 400px;
}

.unknown-container {
  background: #f6f6f6;
  justify-content: center;
}

.history-calendar {
  position: relative;

  fieldset {
    //border: 1px solid #579ced;
    border-radius: 4px;
  }

  input {
    font-weight: 500;
    // padding-top: 12.5px;
    // padding-bottom: 12.5px;
    // padding: 12.5px 14px;
  }
}



.history-provider,
.history-businesses,
.history-status {
  >div {
    font-weight: 500;
  }

  fieldset {
    //border: 1px solid #579ced;
    border-radius: 4px;
  }
}

.rejected-ordersCo-container {
  background: #f6f6f6;
  justify-content: center;
}

.orders-history-filter {
  order: 3;
  fieldset {
    //border: 1px solid #579ced;
    border-radius: 4px;
  }

  >div {
    padding: 0;

    >div {
      >div:first-child {
        margin-left: 0;
      }
    }
  }
}

.header-menu {

  li.MuiBreadcrumbs-li p,
  li.MuiBreadcrumbs-li,
  li.MuiBreadcrumbs-li h6 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.history-businesses {
  .MuiFormLabel-root[data-shrink='false'] {
    top: -7px;
  }

  display: flex;

  .MuiOutlinedInput-root {
    padding-right: 39px !important;
    position: relative;
    height: 40px;
  }

  input {
    caret-color: transparent;
    cursor: pointer;
    top: 0;
    left: 0;
    position: absolute;
    width: 100% !important;
  }
}

.orders-history-main {
  .select-error {
    position: absolute;
    bottom: -22px;
    color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
}

.reports-select {
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .MuiAutocomplete-inputRoot {
    padding-right: 20px !important;
    height: 32px;
  }

  label {
    transform: translate(14px, 16px) scale(1);
    -webkit-transform: translate(14px, 16px) scale(1);
    -moz-transform: translate(14px, 16px) scale(1);
    -ms-transform: translate(14px, 16px) scale(1);
  }

  .MuiInput-input {
    bottom: 0;
    left: 0;
    position: absolute;
    caret-color: transparent;
    cursor: pointer;
    width: 100% !important;
    box-sizing: border-box;
  }
}

@media all and (max-width: 1400px) {
  .orders-history-main {
    display: block;

    .history-calendar {
      >div>div:first-child {
        margin-left: 5px;
      }

      >div>div {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    >div {
      margin: 5px;
      margin-right: 5px;
    }

    >button {
      margin: 10px;
    }
  }
}

.custom-style-AsyncPaginate {
  &:focus {
    border-bottom: 2px solid #2D7FF9;
  }

  &>div:first-of-type {
    width: 232px !important;

    &>div>div:first-of-type {
      width: 95% !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      border-bottom: 2px solid #2D7FF9;
    }

    box-shadow: none;
    // border-bottom: 2px solid #2D7FF9;
    border-radius: 0;
  }
}

.disabledButton {
  background-color: #2D7FF9 !important;
  color: #fff !important;
  opacity: 0.8;
}

.active-location,
.active-brand {
  color: #fff;
  background-color: rgb(38, 132, 255);

  &:hover {
    background-color: rgb(38, 132, 255);
  }
}

.css-x0hb1g-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-x0hb1g-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none;
}

.message-container {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

.message-container.show {
  opacity: 1;
  visibility: visible;
}

.message-container.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.provider-settings-loading {
  position: fixed !important;
}