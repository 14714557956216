.preloader {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 112;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(white, 0.75);
  }
}

.preloader-menu {
  position: relative;
  width: 100%;
  height: calc(100% + 100px);
  display: flex;
  align-items: center;
  justify-content: center;

  &.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 112;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(white, 0.75);
  }
}

.loader_block {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  vertical-align: middle;
  position: relative;
  display: inline-block;
  margin: auto;
}

.loader_block .dot {
  width: 6px;
  height: 6px;
  margin: 0 2px;
  border-radius: 100%;
  display: inline-block;
  animation-fill-mode: both;
  vertical-align: middle;
  background-color: $primary;
}
.loader_dot_1 {
  animation-name: keyframe_1cjd84a;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: -0.3s;
}
.loader_dot_2 {
  animation-name: keyframe_1cjd84a;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: -0.15s;
}
.loader_dot_3 {
  animation-name: keyframe_1cjd84a;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes keyframe_1cjd84a {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  30%,
  50% {
    opacity: 1;
  }
}
