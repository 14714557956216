.help-page {
  h4 {
    margin-bottom: 1rem;
  }
  .help-form {
    box-shadow: 0 0 4px #1717172e;
    border-radius: 4px;
    padding: 16px 20px;
  }
  .help-infos {
    padding: 0 20px;
    a {
      margin-bottom: 1rem !important;
      i {
        font-size: 14px;
        color: #fff;

        background-color: #007bff;
        border-radius: 50%;
        padding: 8px;
      }
    }
  }
}

@media all and (max-width: 1200px ) {
  .help-infos {
    padding: 0 !important;
    > div {
      >div:last-child {
        margin-left: 100px;
      }
    }
  }
}

@media all and (max-width: 700px ) {
  .help-infos {
    > div {
      >div:last-child {
        margin-left: 0;
      }
    }
  }
}