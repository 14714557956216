@import 'fonts';
@import 'variables';
@import 'mixins';
@import 'animations';
@import 'helpers';

// Vendors
@import 'vendor/bootstrap';
@import '~react-datepicker/src/stylesheets/datepicker';

// Components
@import 'components/loader';
@import 'components/sidebar';
@import 'components/forms';
@import 'components/themeItem';
@import 'components/imageUpload';

@import 'components/menu/product';
@import 'components/menu/menuTabNav';
@import 'components/menu/menuCategories';
@import 'components/menu/emptyMenu';
@import 'components/order/orderView';
@import 'components/menu/menuIcons';
@import 'components/menu/modifierItem';
@import 'components/menu/menuModal';
@import 'components/menu/modifierForm';
@import 'components/menu/overviewItems.scss';
@import "components/virtalList.scss";

@import 'common';

// Pages
@import 'pages/auth';
@import 'pages/menu';
@import 'pages/order';
@import 'pages/settings';
@import 'pages/providers';
@import 'pages/tutorials';
@import 'pages/help';

@import 'print';
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap');
