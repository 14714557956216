.dropdown-content {
   .item-renderer {
     display: flex;
     align-items: center;
     gap: 10px;
     padding: 0 10px;
     input {
       span {
         color: black;
       }
     }
   }
}
.selected {
  background-color: rgba(45, 127, 249, 0.08) !important;
  color: black;
}
.dropdown-heading-value {
  color: black;
}
.select-item {
  color: black;
}

//.rmsc .dropdown-container {
//  border: 1px solid #579ced!important;
//}

.custom-multiselect .checkbox input[type="checkbox"] {
  width: 20px; /* Desired width */
  height: 20px; /* Desired height */
  /* Adjust other properties if necessary */
}